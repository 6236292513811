/**
 * by lcs
 * 2019-08-30
 */
!(function(win){
	'use strict';
    var upload = function (file, postData) {
        var data = new FormData();
        for(var k in postData){
            data.append(k, postData[k]);
        }
        data.append('file',file);
        return fetch(win.uploadHost + '/upload', {
            method: 'POST',
            body: data
        }).then(function (res) {
            return res.json()
        });
    };
	var tryUpload = function(file, postData){
		postData = postData || {};
		if( postData.text || postData.showTime ){
            return upload(file, postData);
        }
        return new Promise(function (ok, error) {
            var reader = new FileReader;
            reader.addEventListener('load', function () {
                return ok(sha1(reader.result));
            });
            reader.readAsArrayBuffer(file);
        }).then(function (code) {
            return fetch(win.uploadHost + '/exist?code=' + code.toUpperCase())
        }).then(function (res) {
            return res.json();
        }).then(function (json) {
            if (json.bizContent && json.bizContent.id) {
                return json;
            }
            return upload(file, postData);
        });
	};

	win.fileUpload = function(file, postData){
		return tryUpload(file, postData);
	};
})(window);